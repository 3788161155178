import { useState, useEffect } from "react";
import { Octokit } from "octokit";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

const MyResume = () => {
    const pdfURL = "/Resume.pdf#zoom=FitH";
    return (
        <div>
            <iframe src={pdfURL} width="100%" height="1000px"/>
        </div>   
    );

}

export default MyResume;